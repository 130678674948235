<template>
  <div class="listing">
    <div class="container-fluid sort-row">
      <div class="container">
        <div class="row justify-content-end">
          <div class="col-md-3 col-sm-6">
            <div class="form-group has-search">
              <span class="fa fa-search form-control-feedback"></span>
              <input
                type="text"
                v-model="search"
                @change="searchEvent"
                class="form-control"
                placeholder="Search"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid tab-mimic">
      <div class="container">
        <h3
          @click="updateEventType('upcoming')"
          :class="eventType == 'upcoming' ? 'active' : ''"
        >
          Upcoming Events
        </h3>
        <h3
          :class="eventType == 'completed' ? 'active' : ''"
          @click="updateEventType('completed')"
        >
          Completed Events
        </h3>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper">
            <div class="row" v-if="isWebinarLoader == false">
              <WebinarCard
                v-for="(webinar, webinarIndex) in webinars"
                :key="webinarIndex"
                :webinar="webinar"
              />
              <div class="paging-wrapper">
                <v-pagination
                  v-model="page"
                  :pages="totalPages"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="pageChangeHandler"
                  v-if="totalWebinars > 0"
                />
              </div>
              <empty-list v-if="totalWebinars == 0"></empty-list>
            </div>
            <div class="row" v-else>
              <WebinarLoader></WebinarLoader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebinarCard from "@/components/WebinarCard.vue";
import WebinarService from "@/services/WebinarService.js";
import errorLog from "@/errorLog";
import VPagination from "vue3-pagination";
import "vue3-pagination/dist/vue3-pagination.css";
import WebinarLoader from "@/components/loaders/WebinarLoader";
import EmptyList from "@/components/EmptyList.vue";
export default {
  name: "WebinarListing",
  components: {
    WebinarCard,
    VPagination,
    WebinarLoader,
    EmptyList
  },
  data() {
    return {
      webinars: [],
      isWebinarLoader: true,
      page: 1,
      currentPage: 0,
      totalPages: 0,
      totalWebinars: 0,
      eventType: "upcoming",
      title: "Upcoming Events",
      search: ""
    };
  },
  created() {
    this.getWebinars();
  },
  methods: {
    async getWebinars() {
      this.isWebinarLoader = true;
      const data = {
        search: this.search
      };
      await WebinarService.getWebinars(this.currentPage, data)
        .then(result => {
          this.isWebinarLoader = false;
          if (this.eventType == "upcoming") {
            this.webinars = result.data.upcoming_events.data;
            this.totalPages = result.data.upcoming_events.last_page;
            this.currentPage = result.data.upcoming_events.current_page;
            this.totalWebinars = result.data.upcoming_events.total;
          } else {
            this.webinars = result.data.completed_events.data;
            this.totalPages = result.data.completed_events.last_page;
            this.currentPage = result.data.completed_events.current_page;
            this.totalWebinars = result.data.completed_events.total;
          }
        })
        .catch(error => {
          this.isWebinarLoader = false;
          errorLog.log(error);
        });
    },

    pageChangeHandler(selectedPage) {
      this.currentPage = selectedPage;
      this.getWebinars();
    },
    searchEvent: function() {
      this.currentPage = 1;
      this.getWebinars();
    },
    updateEventType: function(eventType) {
      this.currentPage = 1;
      this.eventType = eventType;
      this.title =
        eventType == "upcoming" ? "Upcoming Events" : "Completed Events";
      this.getWebinars();
    }
  }
};
</script>

<style lang="scss">
@import "@/style/listing.scss";
h3.active {
  color: #f67f29;
}
</style>
