<template>
  <div class="col-md-4">
    <div class="event-card">
      <router-link
        :to="{ name: 'webinarDetails', params: { slug: webinar.slug } }"
      >
        <div class="_image-wrap">
          <img :src="webinar.image" alt="" />
          <div class="_date-box">
            {{ webinar.date }} <br />
            {{ webinar.month }}
          </div>
        </div>
        <div class="_body-wrap">
          <h6>{{ webinar.title }}</h6>
          <p>{{ webinar.time }}</p>
          <!-- <span>₹ {{ webinar.price }}</span> -->
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "WebinarCard",
  props: ["webinar"],
  components: {}
};
</script>

<style lang="scss">
@import "@/style/event-card.scss";
</style>
